#root{
    .systems{
        input[type=file]{
            display: none;
        }
        .logo-image:hover{
            cursor: pointer;
        }
    }

    div.select-control{
        .MuiFormControl-root{
            label.MuiInputLabel-root.MuiInputLabel-formControl.MuiFormLabel-root.Mui-focused{
                background: #1a2035;
                padding: 0 3px;
            }

            label.MuiInputLabel-shrink{
                background: #1a2035;
                padding: 0 3px;
            }

            div.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-formControl{
                height: 48px;
                >div{
                    height: 100%;
                }
            }
        }
    }

    label.MuiInputLabel-shrink{
        color: white;
        background-color: #49a3f1;
        padding: 0px 6px;
        border-radius: 4px;
        min-width:84px;   
    }

    .label-amp{
        label.MuiInputLabel-shrink{
            background-color: green;
        }

    }


    .mapboxgl-popup-content{
        padding: 0;
        background-color: whitesmoke;
        table{
            // border-collapse: separate;
            font-size: 16px;
            thead{
                th{
                    background-color: #49a3f1;
                    color: white;
                    padding: 3px 6px;
                }
            }

            tbody{
                td{
                    background-color: lightgray;
                    color: black;
                    padding: 2px ;
                    font-size: 14px;
                }
            }
        }

        .mapboxgl-popup-close-button{
            display: none;
        }
    }

    .open-map{
        float: right;
        color: white;
    }

    .map-list{
        table tbody tr{
            cursor: pointer;
            span.selected{
                color: #F7931E;
            }
        }
    }



}




.multi-assign-item{
    span.MuiTypography-root.MuiTypography-body1{
        font-size: inherit;
    }
    
}

/* width */
::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #49a3f1;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #49a3f1;
    opacity: 0.8;
}
